<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getImpAssList')">
          <div class="row mb-2">
            <div class="col-sm-4 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Denominazione"
                aria-label=""
                @input="setDenominazioneImpAss"
                :value="denominazioneImpAss"
              />
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                :options="istat_province"
                name="istat_province"
                placeholder="Provincia"
                :value="provinciaImpAss"
                @changeSelect="
                  setProvinciaImpAss($event);
                  changeIsProvinciaSelected($event);
                "
              />
            </div>
            <div class="col-sm-4">
              <input
                type="text"
                class="form-control"
                placeholder="Comune"
                aria-label=""
                @input="
                  inputFocus = 'comuni2';
                  spliceComuniIstat();
                  setComuni();
                "
                v-model="comuneString"
                @keyup.delete="spliceComuniIstat"
                :disabled="!provinciaImpAss"
                autocomplete="off"
              />
              <ListComuni
                v-if="inputFocus === 'comuni2'"
                :list="comuniIstat"
                @hideList="
                  spliceComuniIstat();
                  inputFocus = null;
                "
                @selectedElement="
                  inputFocus = null;
                  comuneString = $event.name;
                  setComuneImpAss($event.value);
                  spliceComuniIstat();
                "
              ></ListComuni>
            </div>
          </div>

          <div class="text-center">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getImpAssList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('resetFilters');
                isProvinciaSelected = false;
                comuneString = '';
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, getCurrentInstance, watch } from "vue";
import { useStore } from "vuex";
import ListComuni from "../../utility/ListComuni.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-assegna-impianto",
  emits: ["resetFilters", "getImpAssList"],
  props: {
    campiLocked: {
      type: String,
      required: true,
    },
    resetted: {
      type: Boolean,
    },
  },
  components: { SelectInput, ListComuni },
  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const istat_province = computed(() =>
      store.getters.getStateFromName("istat_province")
    );
    const keys = ref("ip");
    if (
      !istat_province.value.length ||
      (istat_province.value && !istat_province.value.length)
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const denominazioneImpAss = computed(
      () => store.getters.denominazioneImpAss
    );
    const provinciaImpAss = computed(() => store.getters.provinciaImpAss);
    const comuneImpAss = computed(() => store.getters.comuneImpAss);

    const setDenominazioneImpAss = (e) => {
      const string = e.target.value;
      store.commit("setDenominazioneImpAss", string);
    };

    const setProvinciaImpAss = (event) => {
      store.commit("setProvinciaImpAss", event);
    };

    const setComuneImpAss = (event) => {
      store.commit("setComuneImpAss", event);
    };

    const id_provincia = computed(() => store.getters.provinciaImpAss);
    const comuneString = ref("");
    const isProvinciaSelected = ref(false);

    const changeIsProvinciaSelected = (e) => {
      comuneString.value = "";
      setComuneImpAss(null);
      if (e !== null) {
        isProvinciaSelected.value = true;
      } else {
        isProvinciaSelected.value = false;
      }
    };

    const setComuni = () => {
      if (comuneString.value.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: comuneString.value,
            codp: id_provincia.value,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };
    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    watch(
      () => props.resetted,
      (value) => {
        if (value) {
          comuneString.value = "";
          isProvinciaSelected.value = false;
        }
      }
    );

    // spliceComuniIstat();

    const inputFocus = ref(null);

    return {
      inputFocus,
      denominazioneImpAss,
      provinciaImpAss,
      comuneImpAss,
      setDenominazioneImpAss,
      setProvinciaImpAss,
      setComuneImpAss,
      istat_province,
      changeIsProvinciaSelected,
      isProvinciaSelected,
      setComuni,
      spliceComuniIstat,
      comuneString,
      comuniIstat: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedimpianti_assegna_list")
      ),
    };
  },
});
</script>

<style></style>
